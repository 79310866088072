



































































































































































































































































.main_actions {
  display: flex;
  padding: 5px 0;
  gap: 10px;
  justify-content: end;
  .actions-icons {
    font-size: 14px;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
    color: #777;
    transition: 0.3s;
    &.team_count {
      background-color: #11bfe3;
      color: #fff;
    }
    &.add {
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
    &.edit {
      &:hover {
        background-color: #ffa534;
        color: #fff;
      }
    }
    &.reinvite {
      &:hover {
        background-color: #3c3;
        color: #fff;
      }
    }
    &.delete {
      &:hover {
        background-color: #fb404b;
        color: #fff;
      }
    }
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    // background-color: #23ccef;
    border-color: #00563f;
    background-color: #00563f;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
